<script>
import GlobalVue from '@helper/Global.vue';
import BaseVue from '@frontend/Base.vue';
import Gen from '@helper/Gen';


export default {
    extends: GlobalVue,
    data() {
        return {
          data:{},
          related:{}
        }
    },
    watch: {
        $route() {
           this.getListNews()
           this.refreshMeta()
        }
    },
    async mounted() {
        this.$set(this.$root, 'page', this)
        this.getListNews()
        this.refreshMeta()
    },
    methods: {
      getListNews(){
        Gen.apirest("/news-event/"+this.$route.params.slug, {},(err,resp)=>{
          if(err) console.log("About goodness error "+err)
          if (resp.code == 404) return this.$router.push({name:'404'})
          this.data = resp.data
          this.related = resp.related
        })
      },
      changePage(page=1){
        this.$router.push({query:Object.assign(Gen.clone(this.$route.query),{page:page})})
      },
      refreshMeta(){
        Gen.rest(this.$route.path, {metainfo:true},(err,resp)=>{
          if(err) return console.error(err)
          $("title").text(resp.meta_title)
          $("meta[property='og:image']").attr('content', resp.ss_img)
          $("meta[property='og:title']").attr('content', resp.meta_title)
          $("meta[name='description']").attr('content', resp.ss_meta_desc)
          $("meta[property='og:description']").attr('content', resp.ss_meta_desc)
          $("meta[name='keywords']").attr('content', resp.ss_meta_keyword)
          $("meta[name='author']").attr('content', resp.ss_author)
          $("meta[property='og:site_name']").attr('content', resp.ss_sitename)
          $("meta[property='url']").attr('content', resp.ss_url)
          $("link[rel='canonical']").attr('content', resp.ss_url)
          this.$root.meta = resp
        })
      }
    },
};
</script>

<template>
    <section id="content">

      <div class="content-wrap py-0">
        <section id="page-title" class="page-title-mini">

          <div class="container clearfix">
            <h1>{{web.menu_news_event}}</h1>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><router-link :to="{name:'Index'}">{{web.menu_home}}</router-link></li>
              <li class="breadcrumb-item"><router-link :to="{name:'NewsEvent'}">{{web.menu_news_event}}</router-link></li>
              <li class="breadcrumb-item active" aria-current="page">{{data.an_title}}</li>
            </ol>
          </div>

        </section>
        <section class="section bg_blue_general nomargin">
          <div class="container">
            <div class="article-post">
              <div class="row justify-content-center">
                <div class="col-lg-8 col-md-10">
                  <div class="article-post-head">
                    <span class="article-post__badge">{{web.menu_news_event}}</span>
                    <h2 class="article-post__title">{{data.an_title}}</h2>
                    <div class="article-post-head-meta">
                      <span v-if="data.an_author != null"><i class="icon-user21"></i>{{data.an_author}}</span>
                      <div class="article-post-head-meta__share">
                        <span>{{web.lbl_share}}</span>
                        <ShareNetwork
                            class="social-icon si-colored si-small si-rounded si-facebook"
                            network="facebook"
                            :url="currentUrl"
                            :title="data.an_title"
                            :description="data.an_meta_desc"
                          >
                            <i class="icon-facebook"></i>
                            <i class="icon-facebook"></i>
                        </ShareNetwork>
                        <ShareNetwork
                            class="social-icon si-colored si-small si-rounded si-twitter"
                            network="twitter"
                            :url="currentUrl"
                            :title="data.an_title"
                            :description="data.an_meta_desc"
                          >
                            <i class="icon-twitter"></i>
                            <i class="icon-twitter"></i>
                        </ShareNetwork>
                      </div>
                    </div>
                  </div>
                  <img :src="uploader(data.an_img)" :alt="data.an_title" class="article-post__img" style="width: 100%">
                  <div class="article-post-body" v-html="data.an_desc"></div>
                </div>
                <div class="col-lg-4 col-md-10">
                  <div class="article-post-related">
                    <h3 class="article-post-related__title">Event Terkait</h3>
                    <template v-if="related.length > 0">
                      <router-link :to="{name:'',params:{slug:v.an_slug}}"  class="related-post" v-for="(v,k) in related">
                        <div class="row">
                          <div class="col-5">
                            <img :src="uploader(v.an_img,'150')" :alt="v.an_title" class="related-post__img">
                          </div>
                          <div class="col-7">
                            <h4 class="related-post__title">{{v.an_title}}</h4>
                            <span class="related-post__author" v-if="v.an_author != null"><i
                                class="icon-user21"></i>{{v.an_author}}</span>
                          </div>
                        </div>
                      </router-link> 
                    </template>    
                    <div v-else class="text-center">
                        <p>Tidak ada news & event terkait.</p>
                    </div>               
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section><!-- #content end -->
</template>